<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model
        layout="inline"
        :colon="false"
        :model="form"
        @keyup.enter.native="query"
      >
        <a-form-model-item>
          <a-input
            placeholder="设计编号"
            v-model="form.designCode"
            style="width: 150px"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-input
            placeholder="项目名称"
            v-model="form.name"
            style="width: 150px"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="项目状态"
            v-model="form.projectStatus"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in project_project_status"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="确认状态"
            v-model="form.confirmStatus"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in project_confirm_status"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button @click="query" type="primary">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
        childrenColumnName="splitProjectList"
      >
        <a-table-column title="#" width="28px" fixed="left">
          <template slot-scope="text">
            <!-- 子项目显示一个缩进图标 -->
            <span v-if="text.pid">
              <span
                class="iconfont icon-md-return-right"
                style="font-size: 12px; color: #999; padding-left: 4px"
              ></span>
            </span>
          </template>
        </a-table-column>

        <a-table-column title="设计编号" data-index="designCode" />
        <a-table-column title="项目名称" data-index="name" />

        <a-table-column title="累计进度" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.progress }}%</span>
          </template>
        </a-table-column>

        <a-table-column title="合同金额(万元)" align="right">
          <template slot-scope="text">
            <Money :money="text.projectAmount" />
          </template>
        </a-table-column>

        <a-table-column title="回款金额(万元)" align="right">
          <template slot-scope="text">
            <span
              v-if="
                text.paymentStatus === 'project_payment_status_inconsistent'
              "
            >
              <Money
                :money="text.paymentSum"
                :styleObject="{ color: '#f00' }"
              />
            </span>
            <span v-else>
              <Money :money="text.paymentSum" />
            </span>
          </template>
        </a-table-column>

        <a-table-column title="回款状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="project_payment_status"
              :dictValue="text.paymentStatus"
            />
          </template>
        </a-table-column>

        <a-table-column title="确认状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="project_confirm_status"
              :dictValue="text.confirmStatus"
            />
          </template>
        </a-table-column>

        <a-table-column title="项目状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="project_project_status"
              :dictValue="text.projectStatus"
            />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="edit(text)">修改</a>
          </template>
        </a-table-column>
      </a-table>

      <Detail
        :id="id"
        :text="text"
        :visible="visible"
        @cancel="cancel"
        @refresh="refresh"
      />
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";
import { mapGetters } from "vuex";
import Detail from "./components/detail.vue";

export default {
  components: {
    Detail,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      text: {},
      id: "",
      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    // 进度管理回款状态
    // project_payment_status() {
    //   return this.findDataDict("project_payment_status");
    // },
    // 进度管理确认状态
    project_confirm_status() {
      return this.findDataDict("project_confirm_status");
    },
    // 进度管理项目状态
    project_project_status() {
      return this.findDataDict("project_project_status");
    },
  },

  methods: {
    getList() {
      this.loading = true;

      request({
        url: "/project-service/project/legacyList",
        params: {
          pageNum: this.current,
          pageSize: this.pageSize,
          ...this.form,
          // scaleConfigured: this.selected ? 0 : undefined,
        },
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list.map((item) => {
              return {
                ...item,
                splitProjectList:
                  Array.isArray(item.splitProjectList) &&
                  item.splitProjectList.length > 0
                    ? item.splitProjectList
                    : null,
              };
            });
            this.total = res.totalSize || 0;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    refresh() {
      this.getList();
    },
    cancel() {
      this.visible = false;
      this.id = null;
      this.text = {};
    },
    edit(text) {
      this.id = text.id;
      this.text = text;
      this.visible = true;
    },
  },
};
</script>