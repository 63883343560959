var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form-model',{attrs:{"layout":"inline","colon":false,"model":_vm.form},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"设计编号"},model:{value:(_vm.form.designCode),callback:function ($$v) {_vm.$set(_vm.form, "designCode", $$v)},expression:"form.designCode"}})],1),_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"项目名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"项目状态"},model:{value:(_vm.form.projectStatus),callback:function ($$v) {_vm.$set(_vm.form, "projectStatus", $$v)},expression:"form.projectStatus"}},_vm._l((_vm.project_project_status),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"确认状态"},model:{value:(_vm.form.confirmStatus),callback:function ($$v) {_vm.$set(_vm.form, "confirmStatus", $$v)},expression:"form.confirmStatus"}},_vm._l((_vm.project_confirm_status),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowKey":"id","childrenColumnName":"splitProjectList"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"#","width":"28px","fixed":"left"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(text.pid)?_c('span',[_c('span',{staticClass:"iconfont icon-md-return-right",staticStyle:{"font-size":"12px","color":"#999","padding-left":"4px"}})]):_vm._e()]}}])}),_c('a-table-column',{attrs:{"title":"设计编号","data-index":"designCode"}}),_c('a-table-column',{attrs:{"title":"项目名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"累计进度","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(text.progress)+"%")])]}}])}),_c('a-table-column',{attrs:{"title":"合同金额(万元)","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('Money',{attrs:{"money":text.projectAmount}})]}}])}),_c('a-table-column',{attrs:{"title":"回款金额(万元)","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(
              text.paymentStatus === 'project_payment_status_inconsistent'
            )?_c('span',[_c('Money',{attrs:{"money":text.paymentSum,"styleObject":{ color: '#f00' }}})],1):_c('span',[_c('Money',{attrs:{"money":text.paymentSum}})],1)]}}])}),_c('a-table-column',{attrs:{"title":"回款状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"project_payment_status","dictValue":text.paymentStatus}})]}}])}),_c('a-table-column',{attrs:{"title":"确认状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"project_confirm_status","dictValue":text.confirmStatus}})]}}])}),_c('a-table-column',{attrs:{"title":"项目状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"project_project_status","dictValue":text.projectStatus}})]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(text)}}},[_vm._v("修改")])]}}])})],1),_c('Detail',{attrs:{"id":_vm.id,"text":_vm.text,"visible":_vm.visible},on:{"cancel":_vm.cancel,"refresh":_vm.refresh}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }