<template>
  <a-modal
    title="进度修改"
    :visible="visible"
    width="640px"
    :footer="null"
    @cancel="$emit('cancel')"
    :dialog-style="{ margin }"
  >
    <template v-if="projectType !== 'father-has-kid'">
      <div class="between">
        <a-space>
          <a-select
            placeholder="选择阶段"
            style="width: 420px"
            :disabled="selectDisabled"
            :allowClear="true"
            v-model="selectedId"
          >
            <a-select-option v-for="item in list" :key="item.id">{{
              item.alias
            }}</a-select-option>
          </a-select>
        </a-space>

        <span>
          <a-space>
            <a-icon
              type="left-circle"
              title="向右靠齐"
              class="icon"
              @click="go('left')"
            />
            <a-icon
              type="play-circle"
              title="居中"
              class="icon"
              @click="go('center')"
            />
            <a-icon
              type="right-circle"
              title="向右对齐"
              class="icon"
              @click="go('right')"
            />
          </a-space>
        </span>
      </div>

      <Padding size="large" />

      <a-table
        bordered
        :dataSource="phaseList"
        :loading="loading"
        :pagination="false"
      >
        <a-table-column title="阶段名称">
          <template slot-scope="text">
            {{ text.phaseName }}
          </template>
        </a-table-column>

        <a-table-column title="阶段比例" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff" v-if="text.phasePercent">
              {{
                String(text.phasePercent).indexOf("%") > -1
                  ? text.phasePercent
                  : text.phasePercent + "%"
              }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="是否历史阶段" align="center">
          <template slot-scope="text, row, index">
            <a-radio-group
              :value="text.legacy"
              @change="(e) => onChange(e, text, index)"
            >
              <a-radio
                v-for="item in [
                  {
                    label: '是',
                    value: '1',
                  },
                  {
                    label: '否',
                    value: '0',
                  },
                ]"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-radio>
            </a-radio-group>
          </template>
        </a-table-column>

        <a-table-column title="进度" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff"> {{ text.progress }}% </span>
          </template>
        </a-table-column>
      </a-table>

      <Padding />
      <Padding />
    </template>

    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <template v-if="projectType !== 'kid'">
        <a-form-item label="确认状态">
          <a-select
            v-decorator="[
              'confirmStatus',
              {
                initialValue: text.confirmStatus,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
          >
            <a-select-option
              v-for="item in project_confirm_status"
              :key="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="回款状态">
          <a-select
            v-decorator="[
              'paymentStatus',
              {
                initialValue: text.paymentStatus,

                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
          >
            <a-select-option
              v-for="item in project_payment_status"
              :key="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="项目状态">
          <a-select
            v-decorator="[
              'projectStatus',
              {
                initialValue: text.projectStatus,

                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            @change="onSelectProjectStatus"
          >
            <a-select-option
              v-for="item in project_project_status"
              :key="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </template>

      <div class="right">
        <a-space>
          <a-button @click="$emit('cancel')">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading"
            >提交</a-button
          >
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>
  
<script>
import request from "@/api/request";
import { fetchProgress } from "@/api/project";
import { mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: String,
    },
    text: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      phaseList: [],

      form: this.$form.createForm(this),
      saveLoading: false,

      list: [],
      margin: "0 40px",
      projectPhase: null,

      selectedId: null,
    };
  },

  watch: {
    visible() {
      if (this.visible) {
        if (this.id) {
          this.loading = true;
          fetchProgress({ id: this.id })
            .then((res) => {
              if (Array.isArray(res)) {
                this.phaseList = res;
              }
            })
            .finally(() => {
              this.loading = false;
            });

          request({
            url: "/project-service/project/phasetype/list",
          }).then((res) => {
            this.list = Array.isArray(res)
              ? res.filter((item) => item.alias)
              : [];
          });
        }
      } else {
        this.list = [];
        this.projectPhase = null;
        this.form.resetFields();
        this.phaseList = [];
        this.selectedId = null;
      }
    },

    selectedId() {
      if (this.selectedId) {
        const id = this.selectedId;

        const item = this.list.find((item) => item.id === id);
        if (item && Array.isArray(item.projectPhaseList)) {
          this.projectPhase = id;
          this.phaseList = [
            ...item.projectPhaseList.map((item) => {
              return {
                phaseName: item.name,
                phasePercent: item.percent,
                legacy: "1",
                progress: 100,
              };
            }),
          ];
        }
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    // 进度管理回款状态
    project_payment_status() {
      return this.findDataDict("project_payment_status");
    },
    // 进度管理确认状态
    project_confirm_status() {
      return this.findDataDict("project_confirm_status");
    },
    // 进度管理项目状态
    project_project_status() {
      return this.findDataDict("project_project_status");
    },

    selectDisabled() {
      return this.phaseList.filter((item) => item.id).length > 0;
    },

    projectType() {
      if (this.text.pid) {
        return "kid";
      } else {
        if (
          this.text.splitProjectList &&
          this.text.splitProjectList.length > 0
        ) {
          return "father-has-kid";
        } else {
          return "father-no-kid";
        }
      }
    },
  },

  methods: {
    go(type) {
      if (type === "left") {
        this.margin = "0 40px";
      } else if (type === "center") {
        this.margin = "0 auto";
      } else {
        this.margin = "0 40px 0 auto";
      }
    },

    onChange(e, text, index) {
      this.phaseList.splice(index, 1, {
        ...text,
        legacy: String(e.target.value),
        progress: String(e.target.value) === "1" ? 100 : text.progress,
      });
    },

    onSelectProjectStatus(value) {
      if (value === "project_project_status_completed") {
        this.phaseList = this.phaseList.map((item) => {
          return {
            ...item,
            progress: item.legacy === "1" ? 100 : item.progress,
          };
        });
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          request({
            url: "/project-service/progress/updateHistoryProgress",
            method: "post",
            data: {
              id: this.id,
              ...values,

              projectPhase: this.projectPhase ? this.projectPhase : undefined,
              historyProgress: this.phaseList,
            },
          })
            .then(() => {
              this.$emit("cancel");
              this.$emit("refresh");
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>
  
